import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Widgets from "../components/Widgets";
import GeographicMap from "../components/GeographicMap";
import UPSDevice from "../components/UPSDevice";
import CoolingUnit from "../components/CoolingUnit";
import TemperatureHumidityChart from "../components/TemperatureHumidityChart";
import {
  PADDINGLEFT,
  COOLING_SYSTEM,
  UPS,
  ONLINE_DEVICES,
  CRITICAL_ALARM,
  CRITICAL,
} from "../constants";
import {
  useLocation,
  useAlarm,
  useTenant,
  useLoadingGif,
  TenantIdContext,
} from "@datwyler/shared-components";

let fetchAlarmsInterval;

const Homepage = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const [tenant, setTenant] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [upsDeviceAlarms, setUpsDeviceAlarms] = useState([]);
  const [coolingUnitAlarms, setCoolingUnitAlarms] = useState([]);
  const { isFetchLoading, fetchLocationData, fetchLocations } = useLocation();
  const { isFetchAlarmLoading, fetchAlarmData, fetchAlarms } = useAlarm();
  const {
    fetchTenantById,
    isFetchTenantByIdLoading,
    fetchTenantByIdData,
    updateTenant,
    isUpdateTenantLoading,
    updateTenantResponseData,
    resetUpdateTenantData,
  } = useTenant();
  const { LoadingGif, setIsLoading } = useLoadingGif();

  const zoom = 1;
  const [mapProps, setMapProps] = useState({
    center: {
      // Singapore coordinates
      // lat: 1.3521,
      // lng: 103.8198,
      lat: 0,
      lng: 25,
    },
    projection: "equirectangular",
    zoom: zoom,
    minZoom: zoom,
  });

  useEffect(() => {
    setIsLoading(
      isFetchLoading ||
        isFetchTenantByIdLoading ||
        isUpdateTenantLoading ||
        false
    );
  }, [
    isFetchLoading,
    isFetchAlarmLoading,
    isFetchTenantByIdLoading,
    isUpdateTenantLoading,
  ]);

  useEffect(() => {
    const isScrollBarVisible =
      document.body.scrollHeight > document.body.clientHeight;
    if (isScrollBarVisible) {
      window.dispatchEvent(new Event("changeWidthWithScrollBar"));
    }

    fetchLocations({
      variables: { tenantId: tenantId },
    });

    refetchTenantsById(tenantId);

    intervalFetchAlarmData(tenantId);

    // fetch data every 15sec
    clearInterval(fetchAlarmsInterval);
    fetchAlarmsInterval = setInterval(
      () => intervalFetchAlarmData(tenantId),
      15000
    );

    return () => {
      clearInterval(fetchAlarmsInterval);
    };
  }, [tenantId]);

  const refetchTenantsById = (tenantId) => {
    fetchTenantById({
      variables: { id: tenantId },
    });
  };

  const intervalFetchAlarmData = (tenantId) => {
    if (tenantId) {
      fetchAlarms({
        variables: {
          tenantId: tenantId,
          page: 0,
          size: 999999,
        },
      });
    }
  };

  // Keeping it commented for now if solution accepted will remove it.
  // useEffect(() => {
  //   if (fetchLocationData?.locations) {
  //     setMapProps({
  //       center: {
  //         lat: fetchLocationData.locations[0]?.latitude || 1.3521,
  //         lng: fetchLocationData.locations[0]?.longitude || 103.8198,
  //       },
  //       zoom: zoom,
  //     });
  //   }
  // }, [fetchLocationData]);

  useEffect(() => {
    if (fetchAlarmData?.alarms?.alarms) {
      let UPSDeviceAlarms = [];
      let CoolingUnitAlarms = [];

      fetchAlarmData.alarms.alarms.forEach((alarm) => {
        if (alarm.device?.type === COOLING_SYSTEM) {
          CoolingUnitAlarms.push(alarm);
        } else if (alarm.device?.type === UPS) {
          UPSDeviceAlarms.push(alarm);
        }
      });

      setUpsDeviceAlarms(UPSDeviceAlarms);
      setCoolingUnitAlarms(CoolingUnitAlarms);
    }
  }, [fetchAlarmData]);

  useEffect(() => {
    if (fetchTenantByIdData?.tenantById) {
      const tenant = fetchTenantByIdData.tenantById;
      setTenant(tenant);
      setWidgets(getWidgets(tenant));
    } else {
      setWidgets([]);
    }
  }, [fetchTenantByIdData]);

  const getWidgets = (tenantData) => {
    const widgets = [];

    if (tenantData?.dashboard?.widgets) {
      const widgetsData = tenantData.dashboard.widgets;
      let alarmsActivated = 0;

      widgetsData.forEach((element) => {
        if (element === ONLINE_DEVICES) {
          widgets.push({
            type: ONLINE_DEVICES,
            details: {
              onlineDevices: tenantData.devices?.online || 0,
              totalDevices: tenantData.devices?.total || 0,
            },
          });
        } else if (element === CRITICAL_ALARM) {
          if (tenantData.alarms?.bySeverity) {
            alarmsActivated = tenantData.alarms?.bySeverity[CRITICAL] || 0;
          }

          widgets.push({
            type: CRITICAL_ALARM,
            details: {
              alarmsActivated: alarmsActivated,
            },
          });
        } else {
          if (tenantData.alarms?.byDeviceType) {
            alarmsActivated = tenantData.alarms?.byDeviceType[element] || 0;
          }

          widgets.push({
            type: element,
            details: {
              alarmsActivated: alarmsActivated,
            },
          });
        }
      });
    }

    return widgets;
  };

  return (
    <Box
      style={{ paddingLeft: PADDINGLEFT, paddingTop: 16, paddingBottom: 24 }}
    >
      <LoadingGif />
      <Widgets
        widgets={widgets}
        setWidgets={setWidgets}
        updateTenant={updateTenant}
        tenant={tenant}
        updateTenantResponseData={updateTenantResponseData}
        resetUpdateTenantData={resetUpdateTenantData}
        refetchTenantsById={refetchTenantsById}
      />
      <Grid container sx={{ marginTop: "16px" }}>
        <Grid item xs={6}>
          <GeographicMap
            mapProps={mapProps}
            locations={fetchLocationData?.locations || []}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
            <Grid item xs={12}>
              <UPSDevice upsDeviceAlarms={upsDeviceAlarms} />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "24px" }}>
              <CoolingUnit coolingUnitAlarms={coolingUnitAlarms} />
            </Grid>
          </Grid>
        </Grid>
        <TemperatureHumidityChart
          locations={fetchLocationData?.locations || []}
        />
      </Grid>
    </Box>
  );
};

export default Homepage;
